import { atom, useRecoilState } from 'recoil'

export const maryJaneStateAtom = atom<{
  firstStampId: string
  hasFirstStampBeenCollected: boolean
}>({
  key: 'maryJane',
  default: {
    firstStampId: '',
    hasFirstStampBeenCollected: false
  },
})

export const useMaryJaneState = () => {
  const [state, setMaryJaneState] = useRecoilState(maryJaneStateAtom)

  return {
    setFirstStampId: (firstLink: string) => {
      setMaryJaneState((m) => ({ ...m, firstStampId: firstLink }))
    },
    setFirstStampCollected: () => {
      setMaryJaneState((m) => ({ ...m, hasFirstStampBeenCollected: true }))
    },
    ...state
  }
}