import React from 'react'
import Head from 'next/head'

export default function StonedHead() {
  return (
    <Head>
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <link rel='shortcut icon' href='/images/leaficon.png' />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap" rel="stylesheet"/>


      <title>PayLeaf | A payment solution focused on Cannabis</title>
      <meta
        property='og:title'
        content='PayLeaf | A payment solution focused on Cannabis'
        key='title'
      />
      <meta property="og:type" content="website" /> 
      <meta property='og:image' content='https://www.payleaf.io/images/payleaf.jpg' />
      <meta property="og:image:type" content="image/jpeg" />

      <meta
        property='twitter:title'
        content='PayLeaf | A payment solution focused on Cannabis'
      />
      <meta
        name='description'
        content='Mobile payment made easy. Join the movement in cannabis and start accepting crypto payments in your store ... without the risk of volatility. Built on Solana'
      />
      <meta
        property='og:description'
        content='Mobile payment made easy. Join the movement in cannabis and start accepting crypto payments in your store ... without the risk of volatility. Built on Solana'
      />
      <meta
        property='twitter:description'
        content='Mobile payment made easy. Join the movement in cannabis and start accepting crypto payments in your store ... without the risk of volatility. Built on Solana'
      />
      <meta property='twitter:image' content='https://www.payleaf.io/images/payleaf.jpg' />
      
      <meta property='twitter:card' content='summary_large_image' />
      <meta name="twitter:domain" content="payleaf.io" />
    </Head>
  )
}
