import { CHAIN_NAMESPACES } from '@web3auth/base'
import { SolanaPrivateKeyProvider } from '@web3auth/solana-provider';
import { connection } from '../config/config'

export const web3AuthClientId =
  'BI0dtbOKK-rqWZCdWShmnMThWzHRHitulLRZK52ZycdzRlJ-Tx7gqRZ3nBr4Nf636nXS0yQOECNPLisdFFaQBW0' // get from http^s://dashboard.web3auth.io

export const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.SOLANA,
  chainId: '0x1',
  rpcTarget: connection.rpcEndpoint,
  displayName: 'Solana Mainnet',
  blockExplorer: 'https://explorer.solana.com/',
  ticker: 'SOL',
  tickerName: 'Solana',
}

export const solanaPrivateKeyProvider = new SolanaPrivateKeyProvider({
  config: { chainConfig },
});