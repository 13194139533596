import { useWallet } from '@solana/wallet-adapter-react'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useAsync } from 'react-use'
import { useRecoilState } from 'recoil'
import rpc from '../../../utils/rpc'
import { solanaAuthAtom } from './authAtom'
import { solanaAuthConfig } from './authConfig'
import Cohere from 'cohere-js'
import { buildAuthTx, validateAuthTx, verifyAuthSignature } from './authUtils'
import { trpc } from '../../../utils/trpc'
import { PublicKey } from '@solana/web3.js'
import { useSocialWallet } from '../hooks/useSocialWallet'
import { useStartAuthTx } from './startAuthHook'

export function useNativeOrOAuthWallet() {
  const wallet = useWallet()
  const socialWallet = useSocialWallet()
  const publicKey = wallet.publicKey || (socialWallet.userInfoRes.data?.pubKey ? new PublicKey(socialWallet.userInfoRes.data?.pubKey) : undefined)

  return {
    publicKey,
    pubKey: publicKey ? publicKey.toBase58() : undefined,
    signTransaction: wallet.signTransaction || socialWallet.signTransaction,
    signMessage: wallet.signMessage || socialWallet.signMessage,
    connected: wallet.connected || socialWallet.isLoggedIn,
    isLoggedIn: socialWallet.isLoggedIn || wallet.connected,
    connecting: wallet.connecting || socialWallet.isLoading,
    loginWithGoogle: socialWallet.loginWithGoogle,
    logout: !!wallet.publicKey ? wallet.disconnect : socialWallet.logout,
    isNative: !!wallet.publicKey,
    isWeb3OAuth: !!socialWallet.pubKey
  }
}

export function useUser(enableAutoAuth: boolean = true) {
  const [solanaAuth, setSolanaAuth] = useRecoilState(solanaAuthAtom)
  const {startSignAuthMsg} = useStartAuthTx()
  const wallet = useNativeOrOAuthWallet()
  const [hasDelayPassed, setDelayPassed] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setDelayPassed(true)
    }, 500)
  }, [])

  /* const [publicKey, setPublicKey] = useState(wallet.publicKey)
  
    useEffect(() => {
    if (!publicKey || !wallet.publicKey?.equals(publicKey))
      setPublicKey(wallet.publicKey)
  }, [wallet.publicKey, publicKey])
  */

  const { data, isLoading, error, refetch } = useQuery(
    ['user', wallet.publicKey, solanaAuth?.tx, solanaAuth?.wallet, wallet.signTransaction],
    async () => {
      const publicKey = wallet.publicKey

      if (!publicKey && !solanaAuth?.tx) {
        return null
      }

      if (!wallet.signTransaction || !wallet.publicKey) return null


      const isValidAuthTxExisting = solanaAuth?.tx && publicKey &&
        solanaAuth.wallet.toBase58() === publicKey?.toBase58() && validateAuthTx(solanaAuth.tx, publicKey)

      const isValidSignatureExisting = solanaAuth?.signature && publicKey &&
          solanaAuth.wallet.toBase58() === publicKey?.toBase58() && verifyAuthSignature(publicKey.toBase58(), solanaAuth.signature)

      console.log('valid auth tx', isValidAuthTxExisting, isValidSignatureExisting, publicKey?.toBase58());
      

      if (!isValidAuthTxExisting && !isValidSignatureExisting && publicKey) {
        console.log('setting the modal visible');
        if (wallet.signMessage) {
          console.log('starting auth msg immediately');
          const success = await startSignAuthMsg()
        } else {
          console.log('no sign msg exisiting')
        }
      }

      if (publicKey) {
        try {
          Cohere.identify(publicKey.toBase58(), {
            displayName: publicKey.toBase58(),
          })
        } catch (e) {
          console.error(`error in cohere identify`, e)
        }
      }

      console.log('return true from auth');
      return true
    },
    {
      staleTime: 1000 * 60,
      enabled: enableAutoAuth
    }
  )
  
  const isAuthed = useMemo(() => {
    if ((!solanaAuth?.tx && !solanaAuth?.signature) || !wallet.signMessage || !wallet.publicKey || !solanaAuth.wallet.equals(wallet.publicKey)) {
      console.log('is not authed');
      return false
    }
    
    return true
  }, [wallet.publicKey, solanaAuth?.tx, solanaAuth?.signature, wallet.signMessage])

  return { data, isLoading, error, isAuthed, solanaAuth, refetch }
}

export function useIsUserPlatformAdmin() {
  return trpc.useQuery(['admin.isPlatformAdmin'])
}
