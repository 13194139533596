import {
  createContext,
  FC,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react'
import { PublicKey, Transaction } from '@solana/web3.js'
import {
  CHAIN_NAMESPACES,
  CustomChainConfig,
  IProvider,
  WALLET_ADAPTERS,
} from '@web3auth/base'
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from '@web3auth/openlogin-adapter'
import { SolanaWallet } from '@web3auth/solana-provider'
import { useQuery } from 'react-query'
import { useAsyncFn } from 'react-use'
import { connection } from '../config/config'
import { getUSDCBalance } from '../utils/splUtils'
import { handleTransaction } from '../utils/solUtils'
import { toast } from 'react-hot-toast'
import {
  createTransferAssetTransaction,
  transferAsset,
} from '../modules/compressedNFTs/utils'
import { getWrappedFrontendConnection } from '../modules/compressedNFTs/wrappedConnection'
import { chainConfig, solanaPrivateKeyProvider, web3AuthClientId } from '../auth/web3AuthConfig';

export const SocialWalletContext = createContext<any>(null)

export const SocialWalletProvider: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [inited, setInited] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [web3AuthCore, setWeb3AuthCore] = useState<Web3AuthNoModal | null>(null)
  const [socialWalletProvider, setSocialWalletProvider] =
    useState<IProvider | null>(null)


  useEffect(() => {
    console.log('new infra isLoggedin', isLoggedIn)
  }, [isLoggedIn])

  useEffect(() => {
    const init = async () => {
      try {
        console.log('start init', { web3AuthClientId }, 'cyan')

        console.log({
          clientId: web3AuthClientId,
          web3AuthNetwork: 'cyan', // mainnet, aqua, celeste, cyan or testnet
        }, "chainConfig", chainConfig)

        const web3authCoreInst = new Web3AuthNoModal({
          clientId: web3AuthClientId,
          web3AuthNetwork: 'sapphire_mainnet', // mainnet, aqua, celeste, cyan or testnet
          chainConfig,
        })

        const openloginAdapter = new OpenloginAdapter({
          privateKeyProvider: solanaPrivateKeyProvider,
          adapterSettings: {
            /* whiteLabel: {
              appName: 'Payleaf',
              appUrl: 'https://www.payleaf.io',
              logoLight: 'https://www.payleaf.io/icons/payleaf-logo.png',
              logoDark: 'https://www.payleaf.io/icons/payleaf-logo.png',
              defaultLanguage: 'en',
              mode: 'light', // whether to enable dark mode. defaultValue: false
            },
            loginConfig: {
              // Add login configs corresponding to the provider
              // Google login
              google: {
                name: 'Google Login', // The desired name you want to show on the login button
                verifier: 'payleaf-google-testnet', // Please create a verifier on the developer dashboard and pass the name here
                typeOfLogin: 'google', // Pass on the login provider of the verifier you've created
                clientId:
                  '931954507008-ofk47ksja3arkqrb6pagab67gopi1g4o.apps.googleusercontent.com', // use your app client id you got from google
              },
              // Add other login providers here
            }, */
            uxMode: 'popup',
          },
        })

        web3authCoreInst.configureAdapter(openloginAdapter)

        setWeb3AuthCore(web3authCoreInst)

        await web3authCoreInst.init()

        console.log(
          'inited web3auth',
          web3authCoreInst,
          'provider',
          web3authCoreInst.provider
        )

        if (web3authCoreInst.provider)
          setSocialWalletProvider(web3authCoreInst.provider)

      } catch (error) {
        console.log('error initing', error)
      }

      setInited(true)
    }

    init()
  }, [])

  const [loginWithGoogleRes, loginWithGoogle] = useAsyncFn(async () => {
    if (!inited || !web3AuthCore) {
      console.log('web3auth not initialized yet')
      return
    }

    console.log('login with goggle')

    try {
      const web3authProvider = await web3AuthCore.connectTo(
        WALLET_ADAPTERS.OPENLOGIN,
        {
          mfaLevel: 'default', // Pass on the mfa level of your choice: default, optional, mandatory, none
          // HIGHLIGHTSTART-customAuthStep
          loginProvider: 'google',
        }
      )

      console.log('connected with new provider')

      setSocialWalletProvider(web3authProvider)

      console.log('user info refetched')

      if (web3AuthCore.connected) {
        return true
      }
    } catch (err: any) {
      console.log('err while login with google', err)
      toast.error('Error while logging in with Google: ' + err.message)
    }
  }, [web3AuthCore, inited])

  const userInfoRes = useQuery(
    ['userInfo', socialWalletProvider],
    async () => {
      if (!web3AuthCore) {
        console.log('web3auth not initialized yet')
        return
      }

      console.log('fetch user info')

      const user = await web3AuthCore?.getUserInfo()
      console.log('fetched user info', { user })

      if (!socialWalletProvider) {
        console.log('provider not initialized yet')
        return { user }
      }
      const solanaWallet = new SolanaWallet(socialWalletProvider) // web3auth.provider

      // Get user's Solana public address
      const accounts = await solanaWallet.requestAccounts()

      const connectionConfig: any = await solanaWallet.request({
        method: 'solana_provider_config',
        params: [],
      })

      // Fetch the balance for  the specified public key
      const balance = await getUSDCBalance(new PublicKey(accounts[0]))

      console.log('userinfo', { pubKey: accounts[0], balance })

      return { pubKey: accounts[0], balance, user }
    },
    { enabled: !!web3AuthCore && !!socialWalletProvider }
  )

  const [logoutRes, logout] = useAsyncFn(async () => {
    try {
      console.log('logout triggered')

      await web3AuthCore?.logout()
      await setWeb3AuthCore(null)
      await setSocialWalletProvider(null)
    } catch (err) {
      console.log('err while logging out message', err)
      throw err
    }
  }, [web3AuthCore, socialWalletProvider])

  const [solanaWalletRes, getSolanaWallet] = useAsyncFn(async () => {
    if (!socialWalletProvider || userInfoRes.status !== 'success') return

    const solanaWallet = new SolanaWallet(socialWalletProvider)

    const accounts = await solanaWallet.requestAccounts()

    console.log('solana wallet accounts found after change of res', accounts)

    return solanaWallet
  }, [socialWalletProvider, userInfoRes])

  const [signTxRes, signTransaction] = useAsyncFn(
    async (transaction: Transaction) => {
      try {
        const solanaWallet = await getSolanaWallet()

        if (!solanaWallet) {
          console.error('no solana wallet detected')
          return
        }

        const signedTx = await solanaWallet.signTransaction(transaction)

        return signedTx
      } catch (err) {
        console.log('err while signing tx', err)
        throw err
      }
    },
    [socialWalletProvider, getSolanaWallet]
  )

  const [signMessageRes, signMessage] = useAsyncFn(
    async (message: Uint8Array) => {
      try {
        const solanaWallet = await getSolanaWallet()

        if (!solanaWallet) {
          console.error('no solana wallet detected')
          return
        }

        const signedMessage = await solanaWallet.signMessage(message)

        return signedMessage
      } catch (err) {
        console.log('err while signing message', err)
        throw err
      }
    },
    [socialWalletProvider, getSolanaWallet]
  )

  const [signAndSendTransactionRes, signAndSendTransactionFromWeb3Auth] =
    useAsyncFn(
      async (transaction: Transaction) => {
        try {
          const solanaWallet = await getSolanaWallet()

          if (!solanaWallet) {
            console.error('no solana wallet detected')
            return
          }

          console.log('before sign tx')

          const signedTx = await solanaWallet.signTransaction(transaction)

          console.log('after signed tx')

          if (!signedTx) return

          const serialized = signedTx.serialize({
            verifySignatures: false,
            requireAllSignatures: false,
          })
          const base64 = serialized.toString('base64')

          const signature = await connection.sendEncodedTransaction(base64)

          console.log('tx send sol signature', signature)

          await handleTransaction(signature, {
            showLogs: true,
            commitment: 'confirmed',
          })

          return signature
        } catch (error: any) {
          console.log('err found', error)
          toast.error(
            'Error while signing and sending transaction: ' + error.message
          )
        }
      },
      [socialWalletProvider, getSolanaWallet]
    )

  const [sendTransactionRes, sendTransactionFromWeb3Auth] = useAsyncFn(
    async (transaction: Transaction) => {
      try {
        const serialized = transaction.serialize({
          verifySignatures: false,
          requireAllSignatures: false,
        })
        const base64 = serialized.toString('base64')

        const signature = await connection.sendEncodedTransaction(base64)

        console.log('tx send sol signature', signature)

        await handleTransaction(signature, {
          showLogs: true,
          commitment: 'confirmed',
        })

        return signature
      } catch (err: any) {
        console.log('err found', err)
        toast.error('Error while sending transaction: ' + err.message)
      }
    },
    [socialWalletProvider, getSolanaWallet]
  )

  return (
    <SocialWalletContext.Provider
      value={{
        isLoggedIn: userInfoRes.status === 'success',
        isLoading: !inited || userInfoRes.isLoading,
        initedAndNotLoggedIn:
          (userInfoRes.status === 'error' || userInfoRes.status === 'idle') &&
          inited,
        balance: userInfoRes?.data?.balance,
        pubKey: userInfoRes?.data?.pubKey,
        inited,
        web3AuthCore,
        socialWalletProvider,
        userInfoRes,
        loginWithGoogleRes,
        loginWithGoogle,
        logout,
        getSolanaWallet,
        solanaWalletRes,
        signTransaction,
        signMessage,
        signAndSendTransactionFromWeb3Auth,
        sendTransactionFromWeb3Auth,
      }}
    >
      {children}
    </SocialWalletContext.Provider>
  )
}
