import { atom, useRecoilState } from 'recoil'

export const socialWalletAtom = atom<
  {
    hasBeenLoggedInWithGoogle: boolean
  }
>({
  key: 'socialWallet',
  default: {
    hasBeenLoggedInWithGoogle: false,
  },
})

export const useSocialWalletState = () => {
  const [state, setState] = useRecoilState(socialWalletAtom)

  return {
    hasBeenLoggedInWithGoogle: state?.hasBeenLoggedInWithGoogle ?? false,
    setHasBeenLoggedInWithGoogle: (hasBeenLoggedInWithGoogle: boolean) => {
      setState((state) => ({
        ...state,
        hasBeenLoggedInWithGoogle,
      }))
    }
  }
}
