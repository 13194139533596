import { Metadata, MetadataProgram } from '@metaplex/js'
import { web3 } from '@project-serum/anchor'
import { PublicKey } from '@solana/web3.js'
import axios from 'axios'
import { programs } from 'newMetaplex'
import {
  collections,
  nukedCollection,
  sacCollection,
} from '../config/collectonsConfig'
import config, {
  connection,
  stakingProgramId,
  evolutionProgramId,
  breedingProgramId,
} from '../config/config'
import * as spl from '@solana/spl-token'
import {
  stakingProgram,
  breedingProgram,
  evolutionProgram,
  awakeningProgram,
} from '../config/solanaPrograms'
import { isAfter, isBefore } from 'date-fns'
import { filterNull } from './utils'
import asyncBatch from 'async-batch'
import _ from 'lodash'
import { NftMetadata } from './nftmetaData.type'
import { pub } from './solUtils'

export async function getDexlabPrice(
  pair: 'PUFF/USDC' | 'SOL/USDC' | 'ALL/SOL'
) {
  /* const uris = {
    'PUFF/USDC': 'FjkwTi1nxCa1S2LtgDwCU8QjrbGuiqpJvYWu3SWUHdrV',
    'SOL/USDC': '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT',
    'ALL/SOL': 'HnYTh7fKcXN4Dz1pu7Mbybzraj8TtLvnQmw471hxX3f5',
  }

  const recentPricesRes = await axios.get(
    `https://open-api.dexlab.space/v1/prices/${uris[pair]}/last`
  ) */

  const uris = {
    'PUFF/USDC': {
      currency: 'puff',
      vsCurrency: 'usd',
    },
    'SOL/USDC': {
      currency: 'solana',
      vsCurrency: 'usd',
    },
    'ALL/SOL': {
      currency: 'all',
      vsCurrency: 'usd-coin',
    },
  }

  const res = await axios.get(
    `https://api.coingecko.com/api/v3/simple/price?ids=${uris[pair].currency}&vs_currencies=${uris[pair].vsCurrency}`
  )

  let price = res.data[uris[pair].currency][uris[pair].vsCurrency] as number

  return price
}

export async function getPUFFSolprice() {
  const [puffPrice, solPrice] = await Promise.all([
    getDexlabPrice('PUFF/USDC'),
    getDexlabPrice('SOL/USDC'),
  ])
  return puffPrice / solPrice
}

export async function getSolPrice(usd: number) {
  const solPrice = await getDexlabPrice('SOL/USDC')
  return usd / solPrice
}

export async function getPuffPrice(usd: number) {
  const puffPrice = await getDexlabPrice('PUFF/USDC')
  console.log({ puffPrice })

  return usd / puffPrice
}

export async function solToSpl(amount: number, token: PublicKey) {
  if (token.equals(pub(config.puffToken)))
    return amount / (await getPUFFSolprice())
  if (token.equals(config.allToken))
    return amount / (await getDexlabPrice('ALL/SOL'))

  throw new Error('cannot calculate price for token')
}

export async function splToSol(amount: number, token: PublicKey) {
  if (token.equals(pub(config.puffToken)))
    return amount * (await getPUFFSolprice())
  if (token.equals(config.allToken))
    return amount * (await getDexlabPrice('ALL/SOL'))

  throw new Error('cannot calculate price for token')
}

export async function doesUserOwnNfts(
  ownerAddressString: string,
  opts?: { collections?: typeof collections }
) {
  const stakedNfts = await getStakedNftsForOwner(ownerAddressString, opts)

  if (stakedNfts.length > 0) return true

  const ownedNfts = await getNFTsForOwner(ownerAddressString, opts)
  if (ownedNfts.length > 0) return true

  const evolutionNfts = await getEvolutionNftsForOwner(ownerAddressString, opts)

  if (evolutionNfts.length > 0) return true

  const awakeningAccounts = await getAwakeningNftsForOwner(
    ownerAddressString,
    opts
  )
  if (awakeningAccounts.length > 0) return true

  const breedingNfts = await getBreedingNftsForOwner(ownerAddressString, opts)
  if (breedingNfts.length > 0) return true

  const breedingNftsOfRenters = await getBreedingNftsForRenters(
    ownerAddressString,
    opts
  )
  if (breedingNftsOfRenters.length > 0) return true
  const nftsInRescuePool = await getNftsOfRescuePool(ownerAddressString, opts)
  if (nftsInRescuePool.length > 0) return true

  return false
}

export async function getDistributionOfSacFamilyNfts(ownerAddressString: string) {
  const nfts = await getSacFamilyNfts(ownerAddressString)
  const nftDistribution = {
    awakenSacNfts: 0,
    awakenNacNfts: 0,
    unAwakenSacNfts: 0,
    unAwakenNacNfts: 0,
  }
  for (const nft of nfts) {
    const isAwakened = nft.attributes.some(attr => attr.trait_type === 'Awakened' && attr.value === 'Yes')
    const isSAC = nft.collection.name === 'Stoned Apes'
    const isNAC = nft.collection.name === 'Nuked Apes'

    if (isSAC) {
      if (isAwakened) nftDistribution.awakenSacNfts++
      else nftDistribution.unAwakenSacNfts++
    }
    
    if (isNAC) {
      if (isAwakened) nftDistribution.awakenNacNfts++
      else nftDistribution.unAwakenNacNfts++
    }
  }

  console.log('final', {nftDistribution});
  

  return nftDistribution
}


export async function getSacFamilyNfts(ownerAddressString: string, opts?: { collections?: typeof collections }) {
  const stakedNfts = await getStakedNftsForOwner(ownerAddressString, opts)

  const ownedNfts = await getNFTsForOwner(ownerAddressString, opts)

  const evolutionNfts = await getEvolutionNftsForOwner(ownerAddressString, opts)

  const breedingNfts = await getBreedingNftsForOwner(ownerAddressString, opts)

  const breedingNftsOfRenters = await getBreedingNftsForRenters(ownerAddressString, opts)

  const nftsInRescuePool = await getNftsOfRescuePool(ownerAddressString, opts)

  const awakeningNfts = await getAwakeningNftsForOwner(
    ownerAddressString,
    opts
  )


  const allNFTs = [
    ...stakedNfts,
    ...ownedNfts,
    ...evolutionNfts,
    ...breedingNfts,
    ...breedingNftsOfRenters,
    ...nftsInRescuePool,
    ...awakeningNfts
  ]

  console.log(`found a total of ${allNFTs.length} SAC-family NFTs`);

  return allNFTs
}

export async function getSacFamilyNftsCount(ownerAddressString: string) {
  return (await getSacFamilyNfts(ownerAddressString)).length
}

export async function getNFTsForOwner(
  ownerAddressString: string,
  opts?: { collections?: typeof collections }
): Promise<({mint: string} & NftMetadata)[]> {
  const ownerAddress = new PublicKey(ownerAddressString)
  const allTokens: any[] = []

  const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
    ownerAddress,
    {
      programId: spl.TOKEN_PROGRAM_ID,
    }
  )

  // due to arweave rate limit

  for (let index = 0; index < tokenAccounts.value.length; index++) {
    const tokenAccount = tokenAccounts.value[index]
    const tokenAmount = tokenAccount.account.data.parsed.info.tokenAmount

    if (tokenAmount.amount == '1' && tokenAmount.decimals == '0') {
      let [pda] = await web3.PublicKey.findProgramAddress(
        [
          Buffer.from('metadata'),
          MetadataProgram.PUBKEY.toBuffer(),
          new web3.PublicKey(
            tokenAccount.account.data.parsed.info.mint
          ).toBuffer(),
        ],
        MetadataProgram.PUBKEY
      )
      const accountInfo: any = await connection.getParsedAccountInfo(pda)

      try {
        const metadata: any = new programs.metadata.Metadata(
          ownerAddress.toString(),
          accountInfo.value
        )

        if (
          !metadata?.data?.data?.creators?.find((creator: any) =>
            (opts?.collections ?? collections).find(
              (collection) => collection.creator === creator.address
            )
          )
        )
          continue

        const dataRes = await axios.get(metadata.data.data.uri)

        if (dataRes.status === 200) {
          allTokens.push({
            ...dataRes.data,
            mint: tokenAccount.account.data.parsed.info.mint,
          })
        }
      } catch (e: any) {}
    }
  }

  return allTokens
}

export async function getNFTsForTokens(
  tokens: PublicKey[],
  opts?: { collections?: typeof collections }
): Promise<({mint: string} & NftMetadata)[]> {
  const allTokens: any[] = []

  // due to arweave rate limit

  for (let index = 0; index < tokens.length; index++) {
    const token = tokens[index]

    const metadata = await Metadata.load(
      connection,
      await Metadata.getPDA(token)
    )

    if (
      !metadata?.data?.data?.creators?.find((creator: any) =>
        (opts?.collections ?? collections).find(
          (collection) => collection.creator === creator.address
        )
      )
    )
      continue

    const dataRes = await axios.get(metadata.data.data.uri)

    if (dataRes.status === 200) {
      allTokens.push({
        ...dataRes.data,
        mint: token.toBase58(),
      })
    }
  }

  return allTokens
}

export async function getStakingAccountsForOwner(ownerAddress: string) {
  const accounts = await connection.getParsedProgramAccounts(stakingProgramId, {
    filters: [
      {
        memcmp: {
          offset: 8,
          bytes: ownerAddress,
        },
      },
    ],
  })

  const parsedAccounts = await Promise.all(
    accounts.map(async (a) => {
      return stakingProgram.coder.accounts.decode(
        'StakeAccount',
        a.account.data as any
      ) as ReturnType<typeof stakingProgram.account.stakeAccount.fetch>
    })
  )
  return parsedAccounts
}

export async function getBreedingAccountsOfRenters(ownerAddress: string) {
  const accounts = (await breedingProgram.account.breedingAccount.all()).filter(
    (b: any) => b.account?.rentalUser?.toBase58() === ownerAddress
  )

  return accounts
}

export async function getRentingAccounts(ownerAddress: string) {
  const accounts = (await breedingProgram.account.rentAccount.all()).filter(
    (b) => b.account?.authority?.toBase58() === ownerAddress
  )

  return accounts
}

export async function getEvolutionAccountsForOwner(ownerAddress: string) {
  const accounts = await connection.getParsedProgramAccounts(
    evolutionProgramId,
    {
      filters: [
        {
          memcmp: {
            offset: 8,
            bytes: ownerAddress,
          },
        },
      ],
    }
  )

  const parsedAccounts = await Promise.all(
    accounts.map(async (a) => {
      return evolutionProgram.coder.accounts.decode(
        'EvolutionAccount',
        a.account.data as any
      ) as ReturnType<typeof evolutionProgram.account.evolutionAccount.fetch>
    })
  )
  return parsedAccounts
}

export async function getAwakeningAccountsForOwner(ownerAddress: string) {
  const accounts = await awakeningProgram.account.awakening.all([
    {
      memcmp: {
        offset: 8,
        bytes: ownerAddress,
      },
    },
  ])

  return accounts
}

export async function getBreedingAccountsForOwner(ownerAddress: string) {
  const accounts = await connection.getParsedProgramAccounts(
    breedingProgramId,
    {
      filters: [
        {
          memcmp: {
            offset: 8,
            bytes: ownerAddress,
          },
        },
      ],
    }
  )

  const parsedAccounts = (
    await Promise.all(
      accounts.map(async (a) => {
        try {
          return breedingProgram.coder.accounts.decode(
            'BreedingAccount',
            a.account.data as any
          ) as ReturnType<typeof breedingProgram.account.breedingAccount.fetch>
        } catch (e) {
          console.error('breedingAccount parsing failed')
          return null as unknown as ReturnType<
            typeof breedingProgram.account.breedingAccount.fetch
          >
        }
      })
    )
  ).filter((a) => a)

  return parsedAccounts.filter((a) => !a!.finished)
}

export async function getStakedNftsForOwner(
  ownerAddress: string,
  opts?: { collections?: typeof collections }
) {
  const stakingAccounts = await getStakingAccountsForOwner(ownerAddress)

  const nfts = await getNFTsForTokens(
    stakingAccounts.map((s) => s.token),
    opts
  )
  return nfts
}

export async function getEvolutionNftsForOwner(
  ownerAddress: string,
  opts?: { collections?: typeof collections }
) {
  const stakingAccounts = await getEvolutionAccountsForOwner(ownerAddress)

  const nfts = await getNFTsForTokens(
    stakingAccounts.map((s) => s.token),
    opts
  )
  return nfts
}

export async function getBreedingNftsForOwner(
  ownerAddress: string,
  opts?: { collections?: typeof collections }
) {
  const breedingAccounts = await getBreedingAccountsForOwner(ownerAddress)
  const nfts = await getNFTsForTokens(
    breedingAccounts.map((s) => s.ape1),
    opts
  )
  const nfts2 = await getNFTsForTokens(
    breedingAccounts.filter((s) => !s.rentalUser).map((s) => s.ape2),
    opts
  )
  nfts.push(...nfts2)
  return nfts
}

export async function getAwakeningNftsForOwner(
  ownerAddress: string,
  opts?: { collections?: typeof collections }
) {
  const awakeningAccount = await getAwakeningAccountsForOwner(ownerAddress)

  const nfts = await getNFTsForTokens(
    awakeningAccount.map((s) => s.account.mint),
    opts
  )
  return nfts
}

export async function getBreedingNftsForRenters(
  ownerAddress: string,
  opts?: { collections?: typeof collections }
) {
  const breedingAccounts = await getBreedingAccountsOfRenters(ownerAddress)

  const nfts = await getNFTsForTokens(
    breedingAccounts.map((s) => s.account.ape2),
    opts
  )
  return nfts
}

export async function getNftsOfRescuePool(
  ownerAddress: string,
  opts?: { collections?: typeof collections }
) {
  const accounts = await getRentingAccounts(ownerAddress)

  const nfts = await getNFTsForTokens(accounts.map((s) => s.account.ape, opts))
  return nfts
}

const halvingStartDate = new Date('2022-06-20T14:00:00+00:00')

export async function getPuffStakingRewards({
  rewardsPerDay,
  stakingPubkey,
}: {
  rewardsPerDay: number
  stakingPubkey: PublicKey
}) {
  const now = new Date()

  const stakingAccount = await stakingProgram.account.stakeAccount.fetch(
    stakingPubkey
  )

  const startDate = new Date(
    (stakingAccount.lastWithdraw.toNumber() === 0
      ? stakingAccount.startStaking.toNumber()
      : stakingAccount.lastWithdraw.toNumber()) * 1000
  )

  const secondsPerDay = 86400
  const rewardBeforeHalving = isBefore(startDate, halvingStartDate)
    ? (((isBefore(now, halvingStartDate)
        ? now.getTime()
        : halvingStartDate.getTime()) -
        startDate.getTime()) /
        1000 /
        secondsPerDay) *
      rewardsPerDay
    : 0

  const rewardAfterHalving = isAfter(now, halvingStartDate)
    ? ((now.getTime() -
        (isAfter(startDate, halvingStartDate)
          ? startDate.getTime()
          : halvingStartDate.getTime())) /
        1000 /
        secondsPerDay) *
      rewardsPerDay *
      0.68
    : 0

  const puffRewards = rewardBeforeHalving + rewardAfterHalving

  const days = (now.getTime() - startDate.getTime()) / 1000 / secondsPerDay

  return { puffRewards, puffRewardsPerDay: puffRewards / days }
}

export async function getTransactionsFromAddress(
  wallet: string,
  exclude?: string[],
  limit?: number
) {
  let signatures: string[] = []
  let before: string | undefined = undefined
  while (1) {
    let _signatures = (await connection.getConfirmedSignaturesForAddress2(
      pub(wallet),
      { before }
    )) as any[]

    signatures.push(
      ..._signatures.filter((s) => !s.err).map((s) => s.signature)
    )

    if (limit && signatures.length > limit) break

    if (_signatures.length < 1000) break

    before = _.last(_signatures).signature
  }

  if (exclude) signatures = signatures.filter((s) => !exclude.includes(s))

  if (limit && signatures.length > limit)
    signatures = signatures.slice(0, limit)

  console.log(`${signatures.length} transactions found`)

  const errorsSignatures: string[] = []

  const transactions =
    /*  (await connection.getParsedTransactions(signatures)) ?? */
    (
      await asyncBatch(
        signatures,
        async (signature, i) => {
          try {
            console.log(`${i}: started`)

            const tx = await connection.getParsedTransaction(signature)

            if (!tx || !tx.meta) throw new Error('tx not found')

            const amount =
              tx.meta.postBalances.length >= 5 &&
              tx.meta.preBalances.length >= 5
                ? tx.meta.postBalances[4] - tx.meta.preBalances[4]
                : null

            return { createdAt: new Date(tx.blockTime! * 1000), amount, ...tx }
          } catch (e: any) {
            console.error(`${i}: ${e.message}`)
            errorsSignatures.push(signature)
            return null
          }
        },
        10
      )
    )
      .filter(filterNull)
      .sort((a, b) => b.blockTime! - a.blockTime!)

  return {
    transactions,
    errorsSignatures,
  }
}
