import { Image, ImageProps } from '@chakra-ui/react'

export const GoogleIcon = (image: ImageProps) => {
  return (
    <Image
      height='1.6rem'
      {...image}
      mr='.2rem'
      borderRadius={'full'}
      src={'/images/app/g-logo.webp'}
    />
  )
}
