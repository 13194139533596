import React from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'

export const CleanOutlineButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <Button
      display='flex'
      alignItems={'center'}
      variant='outline'
      color='black'
      border='1px solid #f2f2f2'
      px='1rem'
      fontSize='.9rem'
      fontWeight={700}
      borderRadius={'24px'}
      {...props}
    />
  )
}
