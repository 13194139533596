export const defaultButtonStyle = {
  fontSize: '0.875rem',
  borderRadius: '30px',
  border: '1px solid #181430',
  backgroundColor: '#181430',
  color: '#FFFFFF',
  ':hover': {
    backgroundColor: '#333436',
  },
  ':focus': {
    boxShadow: 'none',
  },
}
