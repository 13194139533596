import { PublicKey } from '@solana/web3.js'
import { differenceInDays, fromUnixTime } from 'date-fns'
import { NftMetadata } from '../utils/nftmetaData.type'
import {
  EAwakenedType,
  getNukedRoleOfMetadata,
  getRoleOfMetadata,
  getRoleOfNft,
  getRoleOfNuked,
  getRoleOfNukedMetadata,
  NukedRole,
  Role,
} from '../utils/solUtils'
import config, { connection } from './config'

export const magicJCollection = {
  name: 'Stoned Ape Magic Js',
  creator: 'BtehquZELQ6tD3dunZpKJZpNDx4fxVB9DfNDQ7P6GL5G',
}

export const getStonedAwakeningCost = (role: Role) => {
  const puffCost = {
    [Role.Chimpion]: 1690,
    [Role.FourRoles]: 3333,
    [Role.Sealz]: 14200,
    [Role.OneOutOfOne]: 16900,
  }
  const allCost = {
    [Role.Chimpion]: 4200,
    [Role.FourRoles]: 6666,
    [Role.Sealz]: 14200,
    [Role.OneOutOfOne]: 16900,
  }

  const puffNeeded = puffCost[role]
  const allNeeded = allCost[role]

  return {
    puff: puffNeeded,
    all: allNeeded,
  }
}

export const getStonedStakingRewards = (role: Role, type: EAwakenedType) => {
  const rewardsPerDay = {
    [EAwakenedType.DEFAULT]: {
      [Role.Chimpion]: 15,
      [Role.FourRoles]: 30,
      [Role.Sealz]: 142,
      [Role.OneOutOfOne]: 169,
    },
    [EAwakenedType.AWAKENED]: {
      [Role.Chimpion]: 30,
      [Role.FourRoles]: 60,
      [Role.Sealz]: 269,
      [Role.OneOutOfOne]: 323,
    },
    [EAwakenedType.SHOCKWAVE]: {
      [Role.Chimpion]: 15 * 4,
      [Role.FourRoles]: 30 * 4,
      [Role.Sealz]: 142 * 2.1,
      [Role.OneOutOfOne]: 169 * 2.1,
    },
  }
  const reward = rewardsPerDay[type][role]

  return reward
}

export const getStonedAllStakingRewards = (role: Role, type: EAwakenedType) => {
  const rewardsPerDay = {
    [EAwakenedType.DEFAULT]: {
      [Role.Chimpion]: 42,
      [Role.FourRoles]: 42,
      [Role.Sealz]: 42,
      [Role.OneOutOfOne]: 42,
    },
    [EAwakenedType.AWAKENED]: {
      [Role.Chimpion]: 69,
      [Role.FourRoles]: 84,
      [Role.Sealz]: 111,
      [Role.OneOutOfOne]: 133,
    },
    [EAwakenedType.SHOCKWAVE]: {
      [Role.Chimpion]: 15 * 4,
      [Role.FourRoles]: 30 * 4,
      [Role.Sealz]: 142 * 2.1,
      [Role.OneOutOfOne]: 169 * 2.1,
    },
  }
  const reward = rewardsPerDay[type][role]
  return reward
}

export const sacCollection = {
  name: 'Stoned Apes',
  symbol: 'SAC',
  mints: require('../assets/mints/sac-mints.json') as string[],
  creator: '7RCBr3ZQ8yhY4jHpFFo3Kmh7MnaCPi1bFuUgXUB9WURf',
  mintsFilename: 'sac-mints',
  getAwakeningCost: (nft: NftMetadata) => {
    const role = getRoleOfMetadata(nft)
    return getStonedAwakeningCost(role.role)
  },
  getAwakeningCostAsync: async (nft: PublicKey) => {
    const role = await getRoleOfNft(nft)
    return getStonedAwakeningCost(role.role)
  },
  getRewardForMetadata: (nft: NftMetadata) => {
    const role = getRoleOfMetadata(nft)
    return getStonedStakingRewards(role.role, role.type)
  },
  getReward: async (nft: PublicKey) => {
    const role = await getRoleOfNft(nft)
    return getStonedStakingRewards(role.role, role.type)
  },
  allReward: async (nft: PublicKey) => 42,
  allRewardForMetadata: (nft: NftMetadata) => 42,
  getRole: async (nft: PublicKey) => {
    return await getRoleOfNft(nft)
  },
}

export const getNukedAwakeningCost = (role: NukedRole) => {
  const puffCost = {
    [NukedRole.Common]: 777,
    [NukedRole.Rare]: 1222,
    [NukedRole.Epic]: 2555,
    [NukedRole.Mystic]: 4444,
    [NukedRole.Legendary]: 8888,
  }
  const allCost = {
    [NukedRole.Common]: 2777,
    [NukedRole.Rare]: 3333,
    [NukedRole.Epic]: 4200,
    [NukedRole.Mystic]: 6900,
    [NukedRole.Legendary]: 9999,
  }

  const puffNeeded = puffCost[role]
  const allNeeded = allCost[role]

  const diff = Math.abs(differenceInDays(fromUnixTime(1653587999), new Date()))

  return {
    puff: puffNeeded + diff * 3,
    all: allNeeded + diff * 10,
  }
}

export const getNukedStakingRewards = (
  role: NukedRole,
  type: EAwakenedType
) => {
  const rewardsPerDay = {
    [NukedRole.Common]: {
      [EAwakenedType.DEFAULT]: 3,
      [EAwakenedType.AWAKENED]: 9,
      [EAwakenedType.SHOCKWAVE]: 15,
    },
    [NukedRole.Rare]: {
      [EAwakenedType.DEFAULT]: 9,
      [EAwakenedType.AWAKENED]: 18,
      [EAwakenedType.SHOCKWAVE]: 30,
    },
    [NukedRole.Epic]: {
      [EAwakenedType.DEFAULT]: 16,
      [EAwakenedType.AWAKENED]: 30,
      [EAwakenedType.SHOCKWAVE]: 60,
    },
    [NukedRole.Mystic]: {
      [EAwakenedType.DEFAULT]: 36,
      [EAwakenedType.AWAKENED]: 70,
      [EAwakenedType.SHOCKWAVE]: 90,
    },
    [NukedRole.Legendary]: {
      [EAwakenedType.DEFAULT]: 69,
      [EAwakenedType.AWAKENED]: 142,
      [EAwakenedType.SHOCKWAVE]: 169,
    },
  }

  const reward = rewardsPerDay[role]

  return reward[type]
}

export const getNukedAllStakingRewards = (
  role: NukedRole,
  type: EAwakenedType
) => {
  const rewardsPerDay = {
    [NukedRole.Common]: {
      [EAwakenedType.DEFAULT]: 21,
      [EAwakenedType.AWAKENED]: 42,
      [EAwakenedType.SHOCKWAVE]: 44,
    },
    [NukedRole.Rare]: {
      [EAwakenedType.DEFAULT]: 21,
      [EAwakenedType.AWAKENED]: 46,
      [EAwakenedType.SHOCKWAVE]: 52,
    },
    [NukedRole.Epic]: {
      [EAwakenedType.DEFAULT]: 21,
      [EAwakenedType.AWAKENED]: 55,
      [EAwakenedType.SHOCKWAVE]: 69,
    },
    [NukedRole.Mystic]: {
      [EAwakenedType.DEFAULT]: 21,
      [EAwakenedType.AWAKENED]: 69,
      [EAwakenedType.SHOCKWAVE]: 95,
    },
    [NukedRole.Legendary]: {
      [EAwakenedType.DEFAULT]: 21,
      [EAwakenedType.AWAKENED]: 90,
      [EAwakenedType.SHOCKWAVE]: 111,
    },
  }
  const roleReward = rewardsPerDay[role]
  return roleReward[type]
}

export const nukedCollection = {
  name: 'Nuked Apes',
  symbol: 'NAC',
  mints: require('../assets/mints/nukedMints.json') as string[],
  creator:
    config.solanaEnv != 'devnet'
      ? /* '99UQMjABnENmcSjvWnZo5G7QpktgZ7Cf14xptCR6sN4S'?? */ 'NUKE6VXDcfyb51yvFwU67hDxj2qMgRdkdtUPKy6D3hC'
      : '86a7KrjxrubTmrkcrykBMr4zR4gXUzaFhUBcMkSV3TWa',
  mintsFilename: 'nukedMints',
  getAwakeningCost: (nft: NftMetadata) => {
    const nukedRole = getNukedRoleOfMetadata(nft)
    return getNukedAwakeningCost(nukedRole)
  },
  getAwakeningCostAsync: async (nft: PublicKey) => {
    const role = await getRoleOfNuked(nft)

    return getNukedAwakeningCost(role.role)
  },
  getReward: async (nft: PublicKey) => {
    const role = await getRoleOfNuked(nft)
    return getNukedStakingRewards(role.role, role.type)
  },
  getRewardForMetadata: (nft: NftMetadata) => {
    const role = getRoleOfNukedMetadata(nft)
    return getNukedStakingRewards(role.role, role.type)
  },
  allRewardForMetadata: (nft: NftMetadata) => {
    const role = getRoleOfNukedMetadata(nft)
    return getNukedAllStakingRewards(role.role, role.type)
  },
  allReward: async (nft: PublicKey) => {
    const role = await getRoleOfNuked(nft)
    return getNukedAllStakingRewards(role.role, role.type)
  },
  getRole: async (nft: PublicKey) => {
    return await getRoleOfNuked(nft)
  },
}

export const honoraryCollection = {
  name: 'SAC Honorary',
  symbol: 'SACH',
  creator: 'FXkkYAiiVRrm2r6uXQojiebRQMEjgobCJYuvKnp7QVQ9',
  getReward: async (nft: PublicKey) => {
    return 30
  },
  getRewardForMetadata: (nft: NftMetadata) => 30,
  getAwakeningCost: (nft: NftMetadata) => {
    return {
      puff: 100000,
      all: 500000,
    }
  },
  getAwakeningCostAsync: async (nft: PublicKey) => {
    return {
      puff: 100000,
      all: 500000,
    }
  },
  allRewardForMetadata: (nft: NftMetadata) => 42,
  allReward: async (nft: PublicKey) => 42,
  getRole: async (nft: PublicKey) => {
    return 'Honorary'
  },
}

export const collections = [sacCollection, nukedCollection, honoraryCollection]
